import { useStaticQuery, graphql } from "gatsby"

export const AllWordpressWpTeamSmall = () => (
  useStaticQuery(
    graphql`
        query {
            allWpAcfFotograf {
                nodes {
                    acfFotografenDetails {
                        farbe
                    }
                    slug
                    link
                    content
                    featuredImage {
                        node {
                            localFile {
                                childImageSharp {
                                    fluid(
                                        maxWidth: 50,
                                        maxHeight: 50,
                                        cropFocus: CENTER
                                    ) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `,
  )
)

