import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import { Headline, StyledHeadlineWrapper } from "../components/headline"
import Img from "gatsby-image"
import VierSwiper from "../components/vierSwiper"
import SEO from "../components/seo"
import { PageSwiper } from "../components/pageSlider"
import { Contact } from "../components/contact"
import { AllWordpressWpTeamSmall } from "../hooks/allWordpressWpTeamSmall"
import styled from "styled-components"

const swiperparams = {
  autoHeight: true,
  navigation: true,
}

const StlyedLink = styled(Link)`
    display: block;
    float: left;
    width: 60px;
    margin-right: 10px;
    text-align: center;
`


export default ({ data }) => {

  const TeamImages = AllWordpressWpTeamSmall()
  let itemsToLoop = data.wpAcfProjekt.AcfProjektDetails.fotos || []

  data.wpAcfProjekt.AcfProjektDetails.videourls &&
  data.wpAcfProjekt.AcfProjektDetails.videourls.map(video => itemsToLoop.unshift(video))

  return (
    <Layout>
      <SEO title={data.wpAcfProjekt.title}/>
      <div className={"container"}>
        <div className={"row"}>
          <div className={"col-10 offset-1"}>
            <StyledHeadlineWrapper>
              <Headline
                headlineH1={data.wpAcfProjekt.title}
              />
            </StyledHeadlineWrapper>
          </div>
          <div className={"col-10 col-md-8 col-lg-6 offset-1"}>
            <div dangerouslySetInnerHTML={{ __html: data.wpAcfProjekt.content }}/>
          </div>
        </div>
      </div>
      <VierSwiper params={swiperparams} items={itemsToLoop}/>

      <div className={"container"}>
        <div className={"row"}>
          <div className={"col-12"}>
            <h4>Powered by</h4>
            {data.wpAcfProjekt.AcfProjektDetails.projectPhotographer.map((item, index) => {
              const photographer = TeamImages.allWpAcfFotograf.nodes.find(user => user.slug === item.slug)
              return (
                <>
                  {photographer.featuredImage.node.localFile &&
                  <StlyedLink to={photographer.link} key={index}>
                    <Img className={"rounded-circle"}
                         css={`filter: url("#${photographer.acfFotografenDetails.farbe}");`}
                         fluid={photographer.featuredImage.node.localFile.childImageSharp.fluid}
                    />
                    {item.title}
                  </StlyedLink>
                  }
                </>
              )
            })}
          </div>
        </div>
      </div>
      <PageSwiper list={data.allWpAcfProjekt.nodes} title={data.wpAcfProjekt.AcfProjektDetails.leistung[0].title}/>
      <Contact/>
    </Layout>
  )
}

export const query = graphql`
    query (
        $slug: String!
    ) {
        wpAcfProjekt(slug: {eq: $slug}) {
            date(formatString: "MMMM Do, YYYY")
            title
            link
            content
            slug
            AcfProjektDetails {
                videourls {
                    videourl
                }
                projectPhotographer {
                    ... on WpAcfFotograf {
                        title
                        slug
                    }
                }
                leistung {
                    ... on WpAcfLeistung {
                        title
                    }
                }
                fotos {
                    sourceUrl
                    localFile {
                        childImageSharp {
                            fluid(
                                maxWidth: 1500,
                                quality: 80
                            ) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
        }
        allWpAcfProjekt {
            nodes {
                slug
                title
                link
                contentType {
                    node {
                        name
                    }
                }
                AcfProjektDetails {
                    projectPhotographer {
                        ... on WpAcfFotograf {
                            title
                        }
                    }
                    leistung {
                        ... on WpAcfLeistung {
                            title
                        }
                    }
                }
                featuredImage {
                    node {
                        localFile {
                            childImageSharp {
                                fluid(
                                    maxWidth: 360,
                                    maxHeight: 200,
                                    quality: 75,
                                    cropFocus: CENTER
                                ) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
