import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import "../styles/swiper.scss"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { device } from "../utils/devices"

const StyledSwiperWrapper = styled.div`
    overflow: hidden;
    margin-top: 100px;
`

const StyledSwiperTitle = styled.h4`
    text-transform: uppercase;
    text-align: center;
    position: absolute;
    left: 0;
    top: 50%;
    transform: rotate(180deg) translateY(50%) translateX(-50%);
    writing-mode: vertical-rl;
    margin: 0;
    bottom: 0;
    text-orientation: mixed;
    font-size: 70px;
    font-weight: bold;
    line-height: 1;
    z-index: 2;
    height: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    
`

const StyledSwiperItem = styled.div`
    text-align: center;
    a {
      color: black;
    }
`

const StyledSwiperItemTitle = styled.h5`
    font-size: 20px;
    color: black;
    margin-top: 20px;
`

const StyledSwiper = styled.div`
    position: relative;
    padding-bottom: 50px;
    padding-top: 50px;
    margin-bottom: 100px;
    @media ${device.tablet} {
      padding-top:100px;
    }
    @media ${device.desktop} {
      padding-bottom: 100px;
      padding-top: 200px;
    }
    &:after {
      z-index: -1;
      content: '';
      position: absolute;
      width: 100vw;
      height: 100%;
      filter: ${props => props.backgroundColor ? `url(#${props.backgroundColor})` : "none"};
      background: ${props => props.backgroundColor ? "white" : "#eeeeee"};
      top: 0;
      left: 6%;
      @media ${device.desktop} {
        left: 85px;
      }
    }
`

const swiperparams = {
  slidesPerView: 2,
  spaceBetween: 32,
  autoplay: {
    delay: 2500,
    disableOnInteraction: true,
  },
  breakpoints: {
    1024: {
      slidesPerView: 4,
    },
    768: {
      slidesPerView: 3,
    },
  },
}

export const PageSwiper = ({ list, title, backgroundColor }) => (
  <StyledSwiperWrapper>
    <StyledSwiper backgroundColor={backgroundColor} className={"container"}>
      {title &&
      <StyledSwiperTitle dangerouslySetInnerHTML={{ __html: title }}/>
      }
      <div className={"row justify-content-center"}>
        <div className={"col-12 offset-2"}>
          <Swiper {...swiperparams}>
            {list.map((item, index) => (
              <SwiperSlide key={index}>
                <StyledSwiperItem>
                  <Link to={item.link}>
                    {item.featuredImage.node.localFile &&
                    <Img fluid={item.featuredImage.node.localFile.childImageSharp.fluid}
                         sizes={{
                           ...item.featuredImage.node.localFile.childImageSharp.fluid,
                           aspectRatio: 16 / 9,
                         }}/>
                    }
                    {item.title &&
                    <StyledSwiperItemTitle dangerouslySetInnerHTML={{ __html: item.title }}/>
                    }
                  </Link>
                </StyledSwiperItem>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </StyledSwiper>
  </StyledSwiperWrapper>
)

